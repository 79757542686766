<template>
  <v-app id="inspire">
    <v-content class="bgc-login">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <div class="text-center">
              <v-icon size="150" color="teal" class="mb-6"> mdi-access-point-network-off </v-icon>
              <h1 class="mb-10">
                {{ $t('shared.networkError') }}
                <v-icon size="35" class="spin"> mdi-emoticon-sad-outline </v-icon>
              </h1>
              <div class="grey--text">
                <v-icon color="info"> mdi-alert-circle-outline </v-icon>
                {{ $t('shared.tryAgain') }}
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'OfflineScreen'
}
</script>
